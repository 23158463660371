import {computed, effect, inject, Injectable, signal} from '@angular/core';
import {User} from '@shared/models/entities/User.type';
import {BaseService} from '@core/services/base.service';
import {catchError, lastValueFrom, Observable, shareReplay, take, tap} from 'rxjs';
import {UserService} from '@shared/services/api/user.service';
import {LocalStorageService} from '@shared/services/storage/local-storage.service';
import {AnonymousTokenDto, OrderUserService} from '@shared/services/api/order-user.service';
import {ANONYMOUS_TOKEN_KEY} from '@core/constants/local-storage-keys.constants';
import {toObservable} from '@angular/core/rxjs-interop';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private USER_LOGGED_IN_COOKIE_FLAG = 'user_logged_in';
  private usersService = inject(UserService);
  private localStorageService = inject(LocalStorageService);
  private cookieService =  inject(CookieService);
  private orderUserService = inject(OrderUserService);
  private pd = inject(PlatformDetectorService);

  loadedUser = signal<User | null>(null);
  loadedUser$ = toObservable(this.loadedUser);
  isUserLogged = computed(() => !!this.loadedUser());

  constructor() {
    super('lokal')
    effect(() => {
      console.log('loaded user = ', this.loadedUser())
    });
    this.getAnonymousTokenDto();
  }

  async reinit() {
    return lastValueFrom(this.getCurrentUserObservable());
  }

  isLoggedInOrAnonymous = () => {
    const isUserLogged = this.isUserLogged();

    const anonymousToken = this.getAnonymousTokenDto();

    return isUserLogged || anonymousToken !== null
  }

  getAnonymousTokenDto = (): AnonymousTokenDto | null => {
    if (this.pd.isServer()) {
      return null;
    }

    return this.localStorageService.getData(ANONYMOUS_TOKEN_KEY)
      ? {
        anonymousToken: this.localStorageService.getData(ANONYMOUS_TOKEN_KEY) as string
      }
      : null;
  }

  getAnonymousTokenDtoIfNotLogged(): AnonymousTokenDto | null {
    if (!this.isUserLogged()) {
      return this.getAnonymousTokenDto();
    }

    return null;
  }

  getCurrentUserObservable() {
    console.log('getCurrentUser')
    if (this.pd.isServer()) {
      return new Observable(observer => {
        observer.next(null);
      });
    }

    const isLoggedFlag = this.cookieService.get(this.USER_LOGGED_IN_COOKIE_FLAG);
    if(!isLoggedFlag || isLoggedFlag === '0') {
      return new Observable(observer => {
        observer.next(null);
      });
    }

    return this.usersService.getCurrentUser().pipe(
      catchError((err) => {
        if (err.error.statusCode === 401) {
          this.logout()
            .pipe(take(1))
            .subscribe();
        }
        throw err;
      }),
      tap((usr) => {
        this.loadedUser.set(usr);
        if (usr && this.getAnonymousTokenDto()) {
          this.anonymousLogout();
        }
      }),
      shareReplay(),
    );
  }

  logout() {
    return this.usersService.logout().pipe(
      tap(() => {
        this.loadedUser.set(null);
      })
    )
  }

  anonymousLogin() {
    return this.orderUserService.getAnonymousToken().pipe(
      tap(tokenDto => {
        this.localStorageService.saveData(ANONYMOUS_TOKEN_KEY, tokenDto.anonymousToken);
        this.getAnonymousTokenDto();
      }),
    );
  }

  anonymousLogout() {
    this.localStorageService.removeData(ANONYMOUS_TOKEN_KEY);
  }
}
